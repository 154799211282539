"use client";

import React from "react";
import { Box, Stack } from "@mui/material";
import useSiteSetting from "@/hooks/useSiteSetting";

export default function FloatingLineContact() {
  const { lineOaUrl } = useSiteSetting();
  return (
    <Box
      sx={{
        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.25)",
        cursor: "pointer",
        position: "fixed",
        width: "64px",
        height: "58px",
        backgroundColor: "red",
        borderRadius: "20px 0 0 20px",
        top: "unset",
        bottom: "100px",
        right: "0",
        backgroundImage: "linear-gradient(180deg, #53c748, #1d7015)",
        zIndex: 1000,
        display: {
          xs: "none",
          // xs: "flex",
          md: "flex",
        },
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <a href={lineOaUrl} target="_blank">
        <Stack>
          <img src="/assets/svg/line.svg" alt="" />
        </Stack>
      </a>
    </Box>
  );
}
